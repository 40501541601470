
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2';

/*composables & states */
import { useOrder } from "@/composables/order/useOrder";
import { useGlobal } from "@/composables/global/useGlobal"
import { useModal } from '@/composables/modal/useModal';
import { stopAudios } from '@/utils/global';

import { currentStatusOngle } from '@/shared/state';


export default defineComponent({
    components: {},
    name: 'productItem',
    props: {
        order: Object
    },
    setup(props) {

        const orderItem = ref(props?.order ?? null)
        const { formatDate } = useGlobal();

        const { updateStatusOrder } = useOrder();
        const { openModal, closeModal } = useModal();

        const changeOrderStatus = (id: number, status: string, currentStatus: string) => {
            const statusMessage = status == "canceled" ? "annulée" : (status == "accepted" ? "acceptée" : ` Livraison de commande N°${id} ${status} `);
            Swal.fire({
                title: "Êtes-vous sûr ?",
                text: (status == "canceled" || status == "accepted") ? `Cette commande sera  ${statusMessage}` : ` Livraison de commande N°${id}`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Oui`,
                cancelButtonText: "Annuler",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const body = { order_id: id, status: status }
                    updateStatusOrder(body, currentStatus).then(async (response) => {
                        if (response) {
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true
                            })
                            Toast.fire({
                                icon: 'success',
                                title: (status == "canceled") ? 'Commande n° ' + id + ' est annulée avec succès.' :
                                    (status == "accepted" ? 'Commande n° ' + id + ' est acceptée avec succès.' : 'Commande n° ' + id + ' est livrée avec succès.')
                            });
                            stopAudios()
                            // if (status == "delivered") {
                            //     await fetchOrdersHistory(1);
                            // }
                        }
                    })
                }
            });
        }
        // Method to format date using Day.js
        const orderCurrentStatus = (currentStatus) => {
            return currentStatus === 'pending' ? 'Accepter' : (currentStatus == 'accepted' ? 'Livrée' : 'Accepter')
        };
        const voirDetails = (order) => {
            stopAudios()
            openModal('modal_order', 'add', order)
        };


        // Method to format date using Day.js
        const btnClass = (currentStatus) => {
            return currentStatus === 'pending' ? 'btn-success' : (currentStatus == 'accepted' ? 'btn-secondary' : 'btn-danger')
        };
        return {
            voirDetails,
            currentStatusOngle,
            changeOrderStatus,
            formatDate,
            orderCurrentStatus,
            btnClass,
            orderItem,
            openModal,
            closeModal,

        };

    },

});
