import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "type type-website"
}
const _hoisted_6 = {
  key: 1,
  class: "type type-borne"
}
const _hoisted_7 = {
  key: 2,
  class: "type type-manager"
}
const _hoisted_8 = {
  key: 0,
  class: "type type-liv"
}
const _hoisted_9 = {
  key: 1,
  class: "type type-at_place"
}
const _hoisted_10 = {
  key: 2,
  class: "type type-cac"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 0,
  class: "type type-pending"
}
const _hoisted_13 = {
  key: 1,
  class: "type type-accepted"
}
const _hoisted_14 = {
  key: 2,
  class: "type type-delivered"
}
const _hoisted_15 = {
  key: 3,
  class: "type type-canceled"
}
const _hoisted_16 = {
  key: 0,
  class: "type type-planified"
}
const _hoisted_17 = {
  key: 1,
  class: "type type-unplanified"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_btn_details = _resolveComponent("btn-details")!
  const _component_btn_default = _resolveComponent("btn-default")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", null, _toDisplayString(_ctx.orderItem?.id ?? ''), 1),
    (_ctx.currentStatusOngle === 'history')
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _toDisplayString(_ctx.formatDate(_ctx.orderItem?.created_at) ?? ''), 1))
      : _createCommentVNode("", true),
    _createElementVNode("td", null, [
      (_ctx.orderItem?.customer)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.orderItem?.customer?.firstName) + " " + _toDisplayString(_ctx.orderItem?.customer?.lastName), 1))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.customerstore)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.orderItem?.customerstore?.firstName) + " " + _toDisplayString(_ctx.orderItem?.customerstore?.lastName), 1))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.borne_cust_name)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.orderItem?.borne_cust_name), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, _toDisplayString(_ctx.orderItem?.total_amount ?? '') + " € ", 1),
    _createElementVNode("td", null, [
      (_ctx.orderItem?.order_by == 'website')
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, " website "))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.order_by == 'borne')
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, " borne "))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.order_by == 'manager')
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, " manager "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, [
      (_ctx.orderItem?.type == 'liv')
        ? (_openBlock(), _createElementBlock("span", _hoisted_8, " Livraison "))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.type == 'at_place')
        ? (_openBlock(), _createElementBlock("span", _hoisted_9, " Sur place "))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.type == 'cac')
        ? (_openBlock(), _createElementBlock("span", _hoisted_10, " Emporter "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.currentStatusOngle === 'history')
      ? (_openBlock(), _createElementBlock("td", _hoisted_11, [
          (_ctx.orderItem?.status == 'pending')
            ? (_openBlock(), _createElementBlock("span", _hoisted_12, " pending "))
            : _createCommentVNode("", true),
          (_ctx.orderItem?.status == 'accepted')
            ? (_openBlock(), _createElementBlock("span", _hoisted_13, " accepted "))
            : _createCommentVNode("", true),
          (_ctx.orderItem?.status == 'delivered')
            ? (_openBlock(), _createElementBlock("span", _hoisted_14, " delivered "))
            : _createCommentVNode("", true),
          (_ctx.orderItem?.status == 'canceled')
            ? (_openBlock(), _createElementBlock("span", _hoisted_15, " canceled "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("td", null, [
      (_ctx.orderItem?.planified)
        ? (_openBlock(), _createElementBlock("span", _hoisted_16, " Oui "))
        : _createCommentVNode("", true),
      (!_ctx.orderItem?.planified)
        ? (_openBlock(), _createElementBlock("span", _hoisted_17, " Non "))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", null, [
      _createVNode(_component_btn_details, {
        onDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.voirDetails(_ctx.order)))
      }),
      (_ctx.orderItem?.status !== 'canceled' && _ctx.orderItem?.status !== 'delivered' && _ctx.currentStatusOngle !== 'history')
        ? (_openBlock(), _createBlock(_component_btn_default, {
            key: 0,
            btnClass: `btn-danger`,
            onDefault: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeOrderStatus(_ctx.orderItem?.id, 'canceled', _ctx.order?.status))),
            title: 'Annuler',
            color: 'red'
          }))
        : _createCommentVNode("", true),
      (_ctx.orderItem?.status !== 'canceled' && _ctx.orderItem?.status !== 'delivered' && _ctx.currentStatusOngle !== 'history')
        ? (_openBlock(), _createBlock(_component_btn_default, {
            key: 1,
            btnClass: `${_ctx.btnClass(_ctx.orderItem?.status)}`,
            onDefault: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changeOrderStatus(_ctx.orderItem?.id, _ctx.currentStatusOngle == 'pending' ? 'accepted' : (_ctx.currentStatusOngle == 'accepted' ? 'delivered' : 'delivered'), _ctx.order?.status))),
            title: `${_ctx.orderCurrentStatus(_ctx.orderItem?.status)}`,
            color: 'red'
          }, null, 8, ["btnClass", "title"]))
        : _createCommentVNode("", true)
    ])
  ]))
}